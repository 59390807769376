
import { defineComponent, onMounted, ref, reactive,watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-menu",
  components: {},

  emits: ["reloadRouter"],

  setup(props, {emit}) {

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      MainMenuConfig: [],
      categMenu: 'dashboard',
      bordSeq :0,
      bordType : '',

    });



    watch(
      () => route.path,
      () => {
        testRoute();
      }
    );

    watch(()=>store.getters.bordSeq, function() {
        state.bordSeq = store.getters.bordSeq;
        state.bordType = store.getters.bordType;
        getMenu(store.getters.bordType == "versement" ? " à verser" : " à éliminer");
    });

    const testRoute = () => {
        if (route.path.indexOf('/bord/')> -1) state.categMenu = 'bordereau';
        if (route.path.indexOf('/board/')> -1) state.categMenu = 'dashboard';
        if (route.path.indexOf('/admin')> -1) state.categMenu = 'admin';
    }

    const reloadRouter = async (item) => {
      
      if (item.route.indexOf("/board/overview") > -1) {
        if(item.route != "/board/overview") {
          window.localStorage.setItem('filterBordereau', item.route);
        } else {
          window.localStorage.setItem('filterBordereau', "");
        }
        
        router.push({ path: item.route }).then(() => {
          emit("reloadRouter", 1);
        })
      } else {
        router.push({ path: item.route });
      }

    }

    const { t, te } = useI18n();
    // const store = useStore();
    
    const scrollElRef = ref<null | HTMLElement>(null);

    /*
    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });
    */

   // console.log("MainMenuConfig",MainMenuConfig);


    const getMenu = async (complement = '') => {

      // console.log('store.getters.bordSeq',store.getters.bordSeq);
      if (store.getters.bordSeq) complement = store.getters.bordType == "versement" ? " à verser" : " à éliminer";

      const myList = await getAxios("/getMenu/"+ complement);
      state.MainMenuConfig = myList.results;
    }

    onMounted(async () => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      testRoute();
      getMenu();    
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      state,
      asideMenuIcons,
      version,
      translate,
      reloadRouter
    };
  },
});


export async function getAxios(request: string): Promise<any> {

  const response = await mAxiosApi.get(request);
  return response.data;

}

